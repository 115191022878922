.spinner-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100vh;
  align-items: center;
  justify-items: center; /* adjusted */
}

.ql-toolbar {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.ql-container {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  min-height: 100px;
}

.custom-error-class,
.custom-error-class.quill > .ql-container {
  color: #f44336;
  border-color: #f44336;
}

.custom-error-class.quill > .ql-toolbar {
  border-top-color: #f44336;
  border-left-color: #f44336;
  border-right-color: #f44336;
}

.custom-error-class.quill > .ql-container > .ql-editor.ql-blank::before {
  color: #f44336;
}

.custom-class-error-message {
  color: #f44336;
  margin-top: 3px;
  margin-left: 14px;
  margin-right: 14pxl;
}
